import {Button, Card, Input, notification, Result, Space, Spin, theme} from "antd";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {parentPath, ViewState} from "../core/utils";
import {get, post} from "../core/request";
import {IconButton} from "../components/icons";
import {ErrorBlock, Modal} from "antd-mobile";

const {useToken} = theme;

interface Surgery {
    uuid: string;
    name: string;
    description: string;
    tags: string;
    n_annotations: number;
}

function TextTag(props: { sid: string, callback: any }) {
    const {token} = useToken();
    const [pressed, setPressed] = useState(false);
    const [value, setValue] = useState('');

    return (
        <Space.Compact style={{
            width: '90%',
            borderRadius: 12,
            paddingTop: 12,
        }}>
            <Input
                defaultValue="Freitext Eingabe"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                style={{
                    borderColor: token.colorPrimary,
                    fontSize: 24,
                    height: 48,
                    lineHeight: '48px',
                    borderTopLeftRadius: 12,
                    borderBottomLeftRadius: 12,
                }}
            />
            <Button
                onClick={
                    () => {
                        if (value !== "") {
                            post(
                                `surgerys/${props.sid}/annotate`,
                                {value: value}).done(() => {
                                setPressed(true);
                                setTimeout(setPressed, 1000, false);
                                setTimeout(props.callback, 0);
                                setValue('');
                            }).fail((response) => {
                                notification.error({
                                    message: 'Tag konnte nicht gespeichert werden.',
                                    description: response?.responseJSON?.data?.description,
                                })
                            })
                        }
                    }
                }
                children={"Speichern"}
                type={pressed ? 'primary' : 'default'}
                style={{
                    borderColor: token.colorPrimary,
                    fontSize: 24,
                    height: 48,
                    lineHeight: '48px',
                    borderTopRightRadius: 12,
                    borderBottomRightRadius: 12,
                }}
            />
        </Space.Compact>
    )

}

function TagButton(props: { tag: string, sid: string, callback: any }) {
    const {token} = useToken();
    const [pressed, setPressed] = useState(false);

    return (
        <Button
            onClick={
                () => {
                    post(
                        `surgerys/${props.sid}/annotate`,
                        {value: props.tag}).done(() => {
                        setPressed(true);
                        setTimeout(setPressed, 1000, false);
                        setTimeout(props.callback, 0);
                    }).fail((response) => {
                        notification.error({
                            message: 'Tag konnte nicht gespeichert werden.',
                            description: response?.responseJSON?.data?.description,
                        })
                    })
                }
            }
            children={props.tag}
            type={pressed ? 'primary' : 'default'}
            style={{
                borderRadius: 12,
                padding: '0 24px',
                fontSize: 24,
                height: 48,
                lineHeight: '48px',
                margin: 12,
                width: '90%',
                borderColor: token.colorPrimary,
            }}
        />

    )
}

export function AnnotationView(props: any) {
    let params = useParams();
    const navigate = useNavigate();

    const {id} = params;
    const location = useLocation();

    const [state, setState] = useState(ViewState.loading);
    const [values, setValues] = useState<string[]>([]);
    const [surgery, setSurgery] = useState({} as Surgery);

    useEffect(() => {
        load_surgery();
    }, []);

    const load_surgery = () => {
        get(`surgerys/${id}`).done((response) => {
            setSurgery(response?.data?.surgery);
            setState(ViewState.success);
        }).fail((response) => {
            notification.error({
                message: 'Operation konnte nicht geladen werden.',
                description: response?.responseJSON?.data?.description,
            })
            setState(ViewState.failed);
        })
    }

    useEffect(() => {
        if (surgery) {
            setValues(surgery?.tags?.replaceAll("\n", ",").split(',').map((tag: string) => tag.trim()));
        } else {
            setValues([]);
        }
    }, [surgery?.uuid])

    const body = () => {
        switch (state) {
            case ViewState.initial:
            case ViewState.loading:
                return <Result
                    icon={<Spin spinning/>}
                    title={"Operation wird geladen"}
                />
            case ViewState.success:
                return (
                    <Space
                        direction={'vertical'}
                        size={0}
                        style={{width: '100%'}}
                    >
                        {
                            values ?
                                values.map((tag: string) => {
                                    return <TagButton
                                        tag={tag}
                                        sid={surgery.uuid}
                                        callback={load_surgery}
                                    />
                                })

                                : <></>
                        }
                        <TextTag
                            sid={surgery.uuid}
                            callback={load_surgery}
                        />
                    </Space>
                )
            default:
            case ViewState.failed:
                return <ErrorBlock
                    status="disconnected"
                    title="Operation konnte nicht geladen werden."
                    description=""
                    fullPage
                />
        }
    }

    return (
        <center>
            <Card
                title={
                    surgery?.name ?
                        surgery.name : ""
                }
                style={{
                    width: '100%',
                    overflow: 'hidden',
                    maxWidth: '1024px',
                }}
                styles={{
                    'body': {
                        height: 'calc(100vh - 24px - 71px - 3px - 75px - 75px - 45px)',
                        overflowY: 'auto',
                    }
                }}
                actions={
                    [
                        <IconButton
                            type={'back'}
                            tooltip={"Zurück"}
                            onClick={() => {
                                navigate(parentPath(location));
                            }}
                        />,
                        <>#</>,
                        <>{surgery.n_annotations}</>,
                        <IconButton
                            type={'rollback'}
                            tooltip={"Letzte Annotation wiederrufen"}
                            onClick={() => {
                                Modal.confirm({
                                    title: 'Annotation wiederrufen',
                                    content: `Soll die letzte Annotation wirklich wiederrufen werden?`,
                                    confirmText: 'Ja',
                                    cancelText: 'Nein',
                                    closeOnMaskClick: true,
                                    onConfirm: () => {
                                        get(
                                            `surgerys/${id}/rollback`
                                        ).done(
                                            (response) => {
                                                notification.success({
                                                    message: 'Annotoration wurde erfolgreich zurückgerufen.',
                                                })
                                                load_surgery();
                                            }
                                        ).fail(
                                            (response) => {
                                                notification.error({
                                                    message: 'Annotoration konnte nicht zurückgerufen werden.',
                                                    description: response?.responseJSON?.data?.description,
                                                })
                                            })
                                    }
                                });
                            }}
                        />,
                    ]
                }
                children={body()}
            />
        </center>
    )
}