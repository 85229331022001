import {useLocation, useNavigate, useRoutes} from "react-router-dom";
import {NotFoundView} from "./views/404-view";
import {SurgeryListView} from "./views/surgery-list-view";
import {SurgeryDetailsView} from "./views/surgery-details-view";
import {AnnotationView} from "./views/annotation_view";
import {LoginView} from "./views/login-view";
import {Redirect} from "./views/redirect-view";
import {useEffect} from "react";
import {token} from "./core/token";


export default function AppRoutes(props: any) {
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(
        () => {
            if (
                (location.pathname !== '/login')
                &&
                (token.get() === "")
            )
            {
                navigate('/login')
            }
        }, [location.pathname]
    )

    return useRoutes([
        {
            path: '/',
            children: [
                {path: '/', element: <Redirect/>},
                {path: 'login', element: <LoginView/>},
                {path: 'surgeries', element: <SurgeryListView/>},
                {path: 'surgeries/:id',  element: <SurgeryDetailsView/>},
                {path: 'surgeries/:id/annotate', element: <AnnotationView/>},
            ]
        },
        {path: '*', element: <NotFoundView/>},
    ])
}