import {Layout, theme} from "antd";
import {useEffect, useState} from "react";
import {get} from "../core/request";
import {logout, token as api_token} from "../core/token";
import {IconButton} from "./icons";
import {useLocation} from "react-router-dom";

const {useToken} = theme;

interface User {
    username: string;
    name: string;
}

export default function Header() {
    const {token} = useToken();
    const location = useLocation();

    return (
        <Layout.Header
            style={{
                backgroundColor: token.colorPrimaryBg,
                overflow: 'hidden',
                paddingLeft: window.innerWidth >= 640 ? 50 : 15,
                borderBottom: '3px solid #3273c3',
            }}
        >
            <h2
                style={{
                    color: token.colorPrimary,
                    margin: 0,
                    padding: 0,
                    width: '90%',
                }}
            >AnnoTapLive</h2>
            {
                location.pathname !== '/login'?
                    <div
                        style={{
                            position: 'absolute',
                            top: 4,
                            right: 12,
                        }}
                    >
                        <IconButton
                            type={'logout'}
                            tooltip={'Abmelden'}
                            onClick={
                                () => {
                                    logout()
                                }
                            }
                            />

                    </div>
                    : null
            }
        </Layout.Header>
    )
}