import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faArrowLeft,
    faArrowRightFromBracket,
    faArrowRightToBracket,
    faCircleCheck,
    faCircleMinus,
    faCirclePlus,
    faCircleXmark,
    faEllipsis, faEraser,
    faFileContract,
    faGear,
    faHouse,
    faListOl,
    faMars,
    faPencil,
    faPeopleGroup,
    faPeopleRoof,
    faPersonWalkingArrowRight,
    faPlus,
    faPrint,
    faQrcode,
    faQuestion,
    faSave,
    faSignsPost,
    faSpinner,
    faStopwatch,
    faTransgender,
    faTrash,
    faUpload,
    faUsersGear,
    faVenus
} from "@fortawesome/free-solid-svg-icons";
import {theme, Tooltip} from "antd";
import {useNavigate} from "react-router-dom";

const {useToken} = theme;


interface IconsSetProps {
    type: 'add' | 'home' | 'checkin' | 'questionmark' | 'allocate' | 'lane' | 'certificates' | 'settings' | 'back' | 'logout' |
        'qr' | 'success' | 'error' | 'loading' | 'm' | 'w' | 'd' | 'delete' | 'login' | 'participants' | 'teams' | 'clubs' |
        'decrease' | 'deallocate' | 'increase' | 'user' | 'license' | 'import' | 'more' | 'annotate' | 'save' | 'print' | 'rollback'
    style?: any,
    className?: string,
    onClick?: any,
}

function IconSet(props: IconsSetProps) {
    switch (props.type) {
        case "home":
            return <FontAwesomeIcon icon={faHouse} {...props}/>
        case "checkin":
            return <FontAwesomeIcon icon={faArrowRightToBracket} {...props}/>
        case "allocate":
            return <FontAwesomeIcon icon={faSignsPost} {...props}/>
        case "lane":
            return <FontAwesomeIcon icon={faStopwatch} {...props}/>
        case "certificates":
            return <FontAwesomeIcon icon={faPrint} {...props}/>
        case "settings":
            return <FontAwesomeIcon icon={faGear} {...props}/>
        case "back":
            return <FontAwesomeIcon icon={faArrowLeft} {...props}/>
        case "logout":
            return <FontAwesomeIcon icon={faArrowRightFromBracket} {...props}/>
        case "login":
            return <FontAwesomeIcon icon={faArrowRightToBracket} {...props}/>
        case "qr":
            return <FontAwesomeIcon icon={faQrcode} {...props}/>

        case "success":
            return <FontAwesomeIcon icon={faCircleCheck} {...props}/>
        case "error":
            return <FontAwesomeIcon icon={faCircleXmark} {...props}/>
        case "loading":
            return <FontAwesomeIcon icon={faSpinner} {...props}/>

        case "m":
            return <FontAwesomeIcon icon={faMars} {...props}/>
        case "w":
            return <FontAwesomeIcon icon={faVenus} {...props}/>
        case "d":
            return <FontAwesomeIcon icon={faTransgender} {...props}/>

        case "add":
            return <FontAwesomeIcon icon={faPlus} {...props}/>
        case "delete":
            return <FontAwesomeIcon icon={faTrash} {...props}/>

        case "decrease":
            return <FontAwesomeIcon icon={faCircleMinus} {...props}/>
        case "deallocate":
            return <FontAwesomeIcon icon={faPersonWalkingArrowRight} {...props}/>
        case "increase":
            return <FontAwesomeIcon icon={faCirclePlus} {...props}/>
        case "more":
            return <FontAwesomeIcon icon={faEllipsis} {...props}/>

        case "participants":
            return <FontAwesomeIcon icon={faListOl} {...props}/>
        case "teams":
            return <FontAwesomeIcon icon={faPeopleGroup} {...props}/>
        case "clubs":
            return <FontAwesomeIcon icon={faPeopleRoof} {...props}/>

        case "user":
            return <FontAwesomeIcon icon={faUsersGear} {...props}/>
        case "license":
            return <FontAwesomeIcon icon={faFileContract} {...props}/>
        case "import":
            return <FontAwesomeIcon icon={faUpload} {...props}/>
        case "annotate":
            return <FontAwesomeIcon icon={faPencil} {...props}/>
        case "rollback":
            return <FontAwesomeIcon icon={faEraser} {...props}/>

        case "save":
            return <FontAwesomeIcon icon={faSave} {...props}/>
        case "print":
            return <FontAwesomeIcon icon={faPrint} {...props}/>
        case "questionmark":
        default:
            //return <QuestionOutlined {...props}/>
            return <FontAwesomeIcon icon={faQuestion} {...props}/>
    }
}


interface IconsButtonProps extends IconsSetProps {
    navigate?: string,
    tooltip?: string,
    onClick?: () => void,
}

function IconButton(props: IconsButtonProps) {
    const {token} = useToken();
    const navigate = useNavigate();

    return <Tooltip
        title={props.tooltip}
        placement='right'
    >
        <IconSet
            {...props}
            style={{
                fontSize: 32,
                padding: 10,
                color: token.colorTextSecondary,
                cursor: 'pointer',
                ...props.style
            }}
            className='clickable'
            onClick={
                props.onClick ? props.onClick :
                    props.navigate ?
                        () => navigate(props.navigate ? props.navigate : '/') :
                        () => console.log("No action defined for icon button")
            }
        />
    </Tooltip>
}

export {IconSet, IconButton}