import {Card, Input, Space, Table, Tag} from "antd";
import {SearchOutlined, WarningTwoTone} from "@ant-design/icons";
import React, {ReactElement, useEffect, useRef, useState} from "react";
import {get} from "../core/request";
import 'dayjs/locale/de';
import {ViewState} from "../core/utils";
import {map} from "jquery";


// utils
function getSorter(type: string, dataIndex: string) {
    switch (type) {
        case "numerical":
            return ((a: any, b: any) => a[dataIndex] - b[dataIndex])
        case"alphabetical":
            return ((a: any, b: any) => String(a[dataIndex]).localeCompare(String(b[dataIndex])))
    }
}

function getRender(render: string) {
    switch (render) {
        case "tags":
            return (tags: string[]) => (
                <>
                    {tags.map(tag => (
                        <Tag key={tag}>
                            {tag}
                        </Tag>
                    ))}
                </>
            )

        default:
            return (content: any) => content
    }
}

function getFilter(column: any) {
    switch (column.render) {
        case "tags":
            return (value: any, record: any) => record[column.dataIndex].some((v: string) => v === value);

        case undefined:
        case null:
        default:
            return (value: any, record: any) => record[column.dataIndex] === value;
    }
}

// default table
interface ApiTableProps {
    id: string,
    url: string,
    title?: ReactElement<any, any>,
    searchable?: boolean | 'footer',
    actions?: any,
    tableProps?: any,
    pagination?: boolean,
    onClick?: undefined | ((event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void)
    size?: any,
    buttons?: ReactElement<any, any>
    reloadSignals: boolean[],
    storeFilters?: boolean,
    style?: any,
}

export function ApiTable(props: ApiTableProps) {
    const [apiData, setApiData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [state, setState] = useState(ViewState.loading);

    const searchRef = useRef("");

    const loadData = () => {
        console.debug("loading data from", props.url);
        get(props.url + "/table")
            .done((response) => {
                    var tabledata = response.data.table
                    setApiData(tabledata.data);
                    setFilteredData(tabledata.data);
                    let columns = tabledata.columns;
                    for (let column of columns) {
                        // split title by linebreak
                        column.title = column.title.split("\n").map((item: string, key: number) => {
                            return <span key={key}>{item}<br/></span>
                        })
                        if (column.sorter) {
                            column.sorter = getSorter(column.sorter, column.dataIndex);
                        }

                        column.onFilter = getFilter(column);

                        if (column.render) {
                            column.render = getRender(column.render);
                        }
                    }
                    if (props.actions) {
                        columns = [...columns,
                            {
                                title: "",
                                render: props.actions,
                                fixed: 'right',
                                width: 10,
                            }];
                    }
                    loadFilters(columns);
                    setState(ViewState.success);
                }
            ).fail((resp) => setState(ViewState.failed))
            .fail((e) => console.log(e));
    }

    useEffect(() => {
        searchData();
    }, [apiData]);

    // init data
    useEffect(() => {
        loadData();
    }, [props.url, props.actions, props.id, ...props.reloadSignals]);

    const handleEvent = (response: any) => {
        for (let event of response?.data?.events) {
            if (event.type === "update") {
                loadData();
            }
        }
    }

    function onChange(pagination: any, filters: any, sorter: any, extra: any) {
        let table = getStoredFilters(props.id);
        if (extra['action'] === "sort") {
            table['sorter'] = [sorter['field'], sorter['order']];
            storeFilters(props.id, table);
            loadFilters(columns);
        } else if (extra['action'] === "filter") {
            table['filters'] = filters;
            storeFilters(props.id, table);
            loadFilters(columns);
        }
    }

    function loadFilters(cols: any) {
        let table = getStoredFilters(props.id);
        for (let colum of cols) {
            // @ts-ignore
            if (colum.sorter) {
                if (table['sorter'][0] === colum.dataIndex) {
                    // @ts-ignore
                    colum.sortOrder = table['sorter'][1];
                } else {
                    colum.sortOrder = null;
                }
            }
            // @ts-ignore
            if (table['filters'].hasOwnProperty(colum.dataIndex)) {
                // @ts-ignore
                colum.filteredValue = table['filters'][colum.dataIndex];
            }
        }
        setColumns(cols);
    }

    function getStoredFilters(id: string) {
        if (!window.localStorage.tables) {
            window.localStorage.tables = JSON.stringify({});
        }
        let tables = JSON.parse(window.localStorage.tables);
        if (!props.storeFilters || !tables.hasOwnProperty(id)) {
            tables[id] = {
                "sorter": [undefined, undefined],
                "filters": []
            };
        }
        return tables[id];
    }

    function storeFilters(id: string, table: {}) {
        let tables = JSON.parse(window.localStorage.tables);
        tables[id] = table;
        window.localStorage.tables = JSON.stringify(tables);
    }

    function getSpinState() {
        switch (state) {
            case ViewState.loading:
                return true;
            case ViewState.success:
                return false;
            case ViewState.failed:
            default:
                return {
                    indicator: <>
                        <WarningTwoTone style={{fontSize: 48, paddingTop: 48}} twoToneColor='#ffc107'/>
                        <br/>
                        <span style={{color: '#6c757d'}}>Daten konnten nicht geladen werden!</span>
                    </>,
                }
        }
    }

    function searchData() {
        let searchVal = searchRef.current.toLowerCase().trim();
        if (searchVal.length === 0 || searchVal === "") {
            // emtpy search -> fill original data
            setFilteredData(apiData);
        } else {
            // filter original data by search string
            setFilteredData(apiData.filter((e) => {
                    for (let key in e) { // loop over all all columns
                        if (key === "key") {
                            continue
                        }
                        let value: string = String(e[key]);
                        if (value.toLowerCase().includes(searchVal)) {  // ignore capitalization
                            return true;  // if one col contains searchString -> keep row
                        }
                    }
                    return false; // else -> remove row
                }
            ));
        }
    }

    const search = <Input
        type="text"
        placeholder="Tabelle durchsuchen..."
        suffix={<SearchOutlined/>}
        allowClear
        onChange={
            (e) => {
                searchRef.current = e.target.value;
                searchData();
            }
        }
    />

    return (
        <Card
            id={props.id}
            headStyle={{backgroundColor: 'rgb(250, 250, 250)', borderBottom: 0}}
            bodyStyle={{
                padding: 0,
                overflow: 'scroll',
                height: 'calc(100% - 44px)',
            }}
            title={props.title}
            style={props.style}
            size={props.size}
            extra={<Space size={12}>
                {props.searchable === true ? (
                    search
                ) : (<></>)}
                {props.buttons ? props.buttons : <></>}
            </Space>}
        >
            <Table
                loading={getSpinState()}
                dataSource={filteredData}
                columns={columns}
                onChange={onChange}
                scroll={{y: 'calc(100% - 44px)'}}
                style={{height: 'calc(100% - 44px)'}}
                size='middle'
                sticky
                footer={() =>
                    <div style={{
                        color: 'transparent',
                        padding: 0,
                        height: props.searchable === 'footer' ? 55 : 25,
                    }}>
                    </div>
                }
                pagination={props.pagination}
                rowClassName={() => props.onClick ? "cursor-pointer" : ""}
                onRow={
                    props.onClick ? (
                        (record: any, rowIndex: any) => {
                            return {
                                // @ts-ignore
                                onClick: () => props.onClick(record, rowIndex)
                            }
                        }
                    ) : (
                        () => {
                        }
                    )
                }
                {...props.tableProps}></Table>
            {
                props.searchable === 'footer' ? (
                    <div
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            backgroundColor: 'rgb(250, 250, 250)',
                            padding: 12,
                            borderTop: '1px solid rgb(240, 240, 240)',
                            zIndex: 10,
                        }}
                    >
                        {search}
                    </div>
                ) : (<></>)
            }
        </Card>
    );
}

ApiTable.defaultProps = {
    searchable: false,
    pagination: false,
    onClick: null,
    size: 'small',
    reloadSignals: [],
    storeFilters: true,
}