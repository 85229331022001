import {Card, Form, Input, notification, Result, Spin, theme} from "antd";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {parentPath, ViewState} from "../core/utils";
import {del, download, get, post} from "../core/request";
import {IconButton} from "../components/icons";
import {ErrorBlock, Modal} from "antd-mobile";
import {token as api_token} from "../core/token";

const {useToken} = theme;


interface Surgery {
    uuid: string;
    name: string;
    description: string;
    tags: string;
    n_annotations: number;
}

export function SurgeryDetailsView(props: any) {
    let params = useParams();
    const navigate = useNavigate();
    const {token} = useToken();

    const {id} = params;
    const location = useLocation();

    const [state, setState] = useState(ViewState.loading);
    const [form] = Form.useForm();

    const [surgery, setSurgery] = useState({} as Surgery);

    useEffect(() => {
        if (id === 'new') {
            form.setFieldsValue({})
            setState(ViewState.success);
            return;
        }

        get(`surgerys/${id}`).done((response) => {
            form.setFieldsValue(response?.data?.surgery);
            setSurgery(response?.data?.surgery);
            setState(ViewState.success);
        }).fail((response) => {
            notification.error({
                message: 'Operation konnte nicht geladen werden.',
                description: response?.responseJSON?.data?.description,
            })
            setState(ViewState.failed);
        })
    }, []);

    const onFinish = (values: any) => {
        post(`surgerys`, values).done(() => {
            notification.success({
                message: 'Operation wurde erfolgreich gespeichert.',
            })
            navigate(parentPath(location));
        }).fail((response) => {
            notification.error({
                message: 'Operation konnte nicht gespeichert werden.',
                description: response?.responseJSON?.data?.description,
            })
        })
    }

    const body = () => {
        switch (state) {
            case ViewState.initial:
            case ViewState.loading:
                return <Result
                    icon={<Spin spinning/>}
                    title={"Operation wird geladen"}
                />
            case ViewState.success:
                return <Form
                    form={form}
                    layout={'horizontal'}
                    labelCol={{span: 7}}
                    wrapperCol={{span: 14}}
                    style={{
                        display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
                    }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name={'uuid'}
                        hidden
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label={"Name"}
                        name={"name"}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label={"Beschreibung"}
                        name={"description"}
                    >
                        <Input.TextArea/>
                    </Form.Item>

                    <Form.Item
                        label={"Tags"}
                        name={"tags"}
                        help={"Komma getrennte Liste der Tags"}
                    >
                        <Input.TextArea/>
                    </Form.Item>

                     <Form.Item
                         label={" "}
                         colon={false}
                    >
                         <br/>
                         {
                             id !== 'new' ? "Anzahl Annotationen: " + (surgery?.n_annotations? surgery.n_annotations : "-/-") : ""
                         }
                     </Form.Item>
                </Form>

            default:
            case ViewState.failed:
                return <ErrorBlock
                    status="disconnected"
                    title="Operation konnte nicht geladen werden."
                    description=""
                    fullPage
                />
        }
    }

    return (
        <center>
            <Card
                title={
                    id === 'new' ?
                        "neue Operation" :
                        "Operation"
                }
                style={{
                    width: '100%',
                    overflow: 'hidden',
                    maxWidth: '1024px',
                }}
                styles={{
                    'body': {
                        height: 'calc(100vh - 24px - 71px - 3px - 75px - 75px - 45px)',
                        overflowY: 'auto',
                    }
                }}
                extra={
                    id === 'new' ?
                        <></> :
                        <IconButton
                            type={'print'}
                            onClick={
                                () => {
                                    download(`surgerys/${id}/export?token=${api_token.get()}`)
                                }
                            }
                            tooltip={"Exportieren"}
                        />
                }
                actions={
                    id === 'new' ?
                        [
                            <IconButton
                                type={'back'}
                                tooltip={"Zurück"}
                                onClick={() => {
                                    navigate(parentPath(location));
                                }}
                            />,
                            <></>,
                            <></>,
                            <IconButton
                                tooltip={"Speichern"}
                                type={'save'}
                                onClick={form.submit}
                            />
                        ] :
                        [
                            <IconButton
                                type={'back'}
                                tooltip={"Zurück"}
                                onClick={() => {
                                    navigate(parentPath(location));
                                }}
                            />,
                            <IconButton
                                type={'save'}
                                tooltip={"Speichern"}
                                onClick={form.submit}
                            />,
                            <IconButton
                                type={'delete'}
                                tooltip={"Löschen"}
                                style={{color: token.colorErrorText}}
                                onClick={() => {
                                    Modal.confirm({
                                        title: 'Operation löschen',
                                        content: `Soll diese Operation wirklich gelöscht werden?`,
                                        confirmText: 'Ja',
                                        cancelText: 'Nein',
                                        closeOnMaskClick: true,
                                        onConfirm: () => {
                                            del(`surgerys/${id}`).done(() => {
                                                navigate("/surgeries");
                                            }).fail(
                                                (response) => {
                                                    notification.error({
                                                        message: 'Operation konnte nicht gelöscht werden.',
                                                        description: response?.responseJSON?.data?.description,
                                                    })
                                                })
                                        }
                                    });
                                }}
                            />,
                            <IconButton
                                type={'annotate'}
                                tooltip={"Annotieren"}
                                onClick={() => {
                                    navigate(`/surgeries/${id}/annotate`);
                                }}
                            />,
                        ]
                }
                children={body()}
            />
        </center>
    )
}