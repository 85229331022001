import {IconButton, IconSet} from "../components/icons";
import {ApiTable} from "../components/table";
import {Space, theme, Tooltip} from "antd";
import {useNavigate} from "react-router-dom";

const {useToken} = theme;

export function SurgeryListView() {
    const navigate = useNavigate();
    const {token} = useToken();

    return (
        <center>
            <ApiTable
                url={"surgerys"}
                id={"surgery-list-table"}
                title={<Space style={{width: '100%'}}>Operationen</Space>}
                searchable
                buttons={
                    <IconButton
                        navigate={"/surgeries/new"}
                        type={"add"}
                        tooltip={"Neue Operation"}
                        style={{
                            color: token.colorPrimary,
                        }}
                    />}
                size={"large"}
                style={{
                    width: '100%',
                    height: 'calc(100vh - 24px - 71px - 3px - 45px)',
                    overflow: 'hidden',
                    maxWidth: '1024px',
                }}
                onClick={(record: any) => {
                    navigate(record.key)
                }}
                actions={
                    (text: any, record: any) => (
                        <Tooltip
                            overlay="Annotieren"
                        >
                            <IconSet
                                type={'annotate'}
                                onClick={
                                    (e:any) => {
                                        navigate(`/surgeries/${record.key}/annotate`);
                                        e.stopPropagation();
                                    }
                                }
                            />
                        </Tooltip>
                    )
                }
            />
        </center>
    )
}