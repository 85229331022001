import {ErrorBlock} from "antd-mobile";
import {IconButton} from "../components/icons";

export function NotFoundView() {
    return <ErrorBlock
        status='empty'
        title='404 Not Found'
        description='Die angeforderte Seite konnte nicht gefunden werden.'
        fullPage
        children={<IconButton navigate={"/"} type={"home"} tooltip={"Zurück zu Home"}/>}
    />
}