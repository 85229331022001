import {IconButton} from "../components/icons";
import {Card, Form, Input} from "antd";
import {post} from "../core/request";
import {useNavigate} from "react-router-dom";
import { token } from "../core/token";

export function LoginView() {
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const login = () => {
        const values = form.getFieldsValue();
        post(
            'login',
            {
                username: values.username,
                password: values.password
            }
        ).done(
            (response) => {
                token.set(response.data.token);
                setTimeout(navigate, 0, '/surgeries')
            }
        ).fail(
            (response) => {
                form.resetFields()
            }
        );
    }

    return (
        <center>
            <Card
                title={"Login"}
                style={{
                    marginTop: '2em',
                    width: Math.min(500, window.innerWidth - 40),
                }}
            >
                <Form
                    layout={"horizontal"}
                    form={form}
                    style={{
                        paddingBottom: 0
                    }}
                >
                    <Form.Item
                        label={"Name"}
                        name={"username"}
                    >
                        <Input type="text" placeholder="Username"/>
                    </Form.Item>
                    <Form.Item
                        label={"Password"}
                        name={"password"}
                    >
                        <Input type="password" placeholder="Password"/>
                    </Form.Item>
                    <Form.Item
                        style={{
                            marginBottom: 0
                        }}
                    >
                        <IconButton type="login" onClick={login} tooltip={"Anmelden"}/>
                    </Form.Item>
                </Form>
            </Card>
        </center>
    );
}