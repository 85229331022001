// @ts-ignore
import {v4 as get_uuid} from "uuid";
import {ArgsProps as NotificationArgsProps} from "antd/lib/notification/interface";
import {notification} from "antd";
import {ReactElement} from "react";

interface NotificationData {
    'title': string,
    'content': string | ReactElement,
    'type': number,
    'job_uuid'?: string,
    'duration'?: number
}

const number2notificationType = (type: number) => {
    switch (type) {
        case 0:
        default:
            return undefined
        case 1:
            return 'success'
        case 2:
            return 'info'
        case 3:
            return 'warning'
        case  4:
            return 'error'
    }
}

const showNotification = (data: NotificationData) => {
    let uuid = get_uuid();
    let config: NotificationArgsProps = {
        message: data.title,
        description: data.content,
        type: number2notificationType(data.type),
        key: uuid,
        onClick: () => {
            notification.destroy(uuid)
        }
    }
    if (data.duration) {
        config['duration'] = data.duration;
    }

    setTimeout(notification.open, 0, config); // handle popup async

}

const handlePopups = (data: NotificationData) => {
    // place to handle special popups, we don't have any yet
    showNotification(data);
}

export {handlePopups, showNotification}