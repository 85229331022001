import {Layout} from "antd";
import React from "react";

import Footer from "./components/footer";
import Header from "./components/header";
import {token} from "./core/token";

export default function AppLayout({children}: { children: JSX.Element }) {
    return (
        <Layout style={{height: '100vh'}}>
            <Layout>
                <Header/>
                <div className='scrollable'>
                    <Layout.Content>
                        {children}
                    </Layout.Content>
                    <Footer/>
                </div>
            </Layout>
            <a
                id='downloadAnchor'
                target='_blank'
                style={{display: 'none'}}
                download
                // add token
                referrerPolicy={'origin'}
            />
        </Layout>
    )
}