import {v4} from "uuid";

export enum ViewState {
    initial,
    loading,
    success,
    failed
}

export const getRoot = (location:any) => {
    let root = location.pathname.split('/')

    root = root.filter((value:any) => {
        return value !== ''
    })
    if (root.length === 0) {
        root = ['']
    }

    return root
}

export const parentPath = (location:any) => {
    let path = getRoot(location)
    let target = "/"
    for (let i = 0; i < path.length - 1; i++) {
        target += path[i] + '/'
    }

    return target
}

export const clientID = v4();