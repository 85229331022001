import * as $ from 'jquery'
import {APIHost} from "../config";
import {handlePopups, showNotification} from "./popup";
import {clientID} from "./utils";
import {token, logout} from "./token";

interface requestProps {
    action: string,
    method: string,
    data?: any,
    dataType?: string,
    contentType?: string,
}

function request(props: requestProps): JQueryPromise<any> {
    return $.ajax({
        ...props,
        url: new URL(props.action, APIHost).toString(),
        headers: {
            'X-JWTToken': token.get(),
            'X-ClientID': clientID,
        },
    }).fail(  // handle error codes
        (jqXHR) => {
            switch (jqXHR.status) {
                case 404: // not found
                    console.error(jqXHR);
                    break
                case 498: // token expired
                case 401: // unauthorized
                    console.log("server requested to logout")
                    logout();
                    break
                case 0: // no connection
                    console.error(jqXHR);
                    showNotification({
                        title: "Keine Verbindung",
                        content: "Es konnte keine Verbindung zum Server hergestellt werden.",
                        type: 3
                    })
                    break
                default:
                    console.error("Request Failed:", jqXHR.status, jqXHR.responseJSON);
            }
        }
    ).always(
        checkForNotifications
    )
}

function checkForNotifications(data: any, status: any, jqXHR: any) {
    let notifications;
    if (typeof jqXHR === "string") {
        notifications = data.responseJSON?.notifications
    } else {
        notifications = jqXHR.responseJSON?.notifications
    }
    notifications?.forEach(
        handlePopups
    )
}

function get(uri: string): JQueryPromise<any> {
    return request({
        action: uri,
        method: "GET"
    });
}

function post(uri: string, data: { [key: string]: any }): JQueryPromise<any> {
    return request({
        action: uri,
        method: "POST",
        data: JSON.stringify(data),
        dataType: "json",
        contentType: "application/json",
    });
}

function patch(uri: string, data: { [key: string]: any }): JQueryPromise<any> {
    return request({
        action: uri,
        method: "PATCH",
        data: JSON.stringify(data),
        dataType: "json",
        contentType: "application/json",
    });
}

function put(uri: string, data: { [key: string]: any }): JQueryPromise<any> {
    return request({
        action: uri,
        method: "PUT",
        data: JSON.stringify(data),
        dataType: "json",
        contentType: "application/json",
    });
}

function del(uri: string): JQueryPromise<any> {
    return request({
        action: uri,
        method: "DELETE",
    });
}

const download = (uri: string) => {
    const downloadAnchor = window.document.getElementById('downloadAnchor') as HTMLAnchorElement;
    downloadAnchor.href = new URL(uri, APIHost).toString();
    downloadAnchor.click();
    // todo handle safari issues with different origin
}


export {get, post, patch, put, del, download}