import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

export function Redirect() {
    const navigate = useNavigate();

    useEffect(() => {
        console.log("Redirecting to /surgeries");
        navigate("surgeries")
    }, []);

    return <></>
}