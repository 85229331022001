import React from 'react';
import {ConfigProvider} from "antd";
import deDE from 'antd/lib/locale/de_DE';
import {HashRouter} from "react-router-dom";

import AppLayout from "./layout";
import AppRoutes from "./routes";

import './style/app.css'

export default function App(props: any) {
    return (
        <ConfigProvider
            locale={deDE}
            theme={{
                token: {
                    colorPrimary: "#a0b400",
                    fontSize: 20,
                }
            }}
        >
            <HashRouter>
                <AppLayout>
                    <AppRoutes/>
                </AppLayout>
            </HashRouter>
        </ConfigProvider>
    )
}



